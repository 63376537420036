import React from "react"
import {
  NavIcon,
  SidebarNav,
  SidebarWrap,
  SidebarData,
  HeadContainer,
} from "./SidebarElements"
import { NavLink } from "react-router-dom"

const Sidebar = () => {
  return (
    <>
      <SidebarNav>
        <SidebarWrap>
          <NavIcon to="#">
            <HeadContainer>
              Logo
              <p>Admin</p>
            </HeadContainer>
          </NavIcon>
          {SidebarData.map((item, index) => {
            return (
              <NavLink
                to={item.path}
                key={index}
                className="link"
                activeclassname="active"
              >
                <div className="link_text">{item.name}</div>
              </NavLink>
            )
          })}
        </SidebarWrap>
      </SidebarNav>
    </>
  )
}

export default Sidebar
