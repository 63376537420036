import React from "react"
import Select from "./Select"
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Paper,
  TableRow,
  TablePagination,
  TableFooter,
  Box,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import IconButton from "@mui/material/IconButton"
import FirstPageIcon from "@mui/icons-material/FirstPage"
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight"
import LastPageIcon from "@mui/icons-material/LastPage"
import { useTheme } from "@mui/material/styles"
import PropTypes from "prop-types"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}))

function TablePaginationActions(props) {
  const theme = useTheme()
  const { count, page, rowsPerPage, onPageChange } = props

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  )
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
}

const Product = (props) => {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - props.subServices.length)
      : 0

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Service</TableCell>
            <TableCell align="center">Sample Name</TableCell>
            <TableCell align="center">Sample Lot</TableCell>
            <TableCell align="center">Sample Description</TableCell>
            <TableCell align="center">วันที่รับตัวอย่าง</TableCell>
            <TableCell align="center">วันที่คาดว่าจะเสร็จ</TableCell>
            <TableCell align="center">status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? props.subServices.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : props.subServices
          ).map((row) => (
            <StyledTableRow key={row._id}>
              <StyledTableCell
                style={{ width: 120 }}
                component="th"
                scope="row"
                align="center"
              >
                {row.service}
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: 120 }}>
                {props.isProduct.productSampleName}
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: 100 }}>
                {props.isProduct.productLotNumber}
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: 120 }}>
                {props.isProduct.productSampleDescription}
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: 120 }}>
                {props.isProduct.sampleTime}
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: 160 }}>
                {props.isProduct.expTime}
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: 200 }}>
                <Select
                  setSubServices={props.setSubServices}
                  row={row}
                  subServices={props.subServices}
                  isProduct={props.isProduct}
                />
              </StyledTableCell>
            </StyledTableRow>
          ))}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, { label: "All", value: -1 }]}
              count={props.subServices.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}
export default Product
