import React from "react"
import Sidebar from "../component/Sidebar/Sideber"
import RequestTable from "../component/Request/RequestTable"

const RequestTablePage = () => {
  return (
    <div>
      <Sidebar />
      <RequestTable />
    </div>
  )
}

export default RequestTablePage
