import React from "react"
import Sidebar from "../component/Sidebar/Sideber"
import Request from "../component/Request/Request"

const RequestPage = () => {
  return (
    <div>
      <Sidebar />
      <Request />
    </div>
  )
}

export default RequestPage
