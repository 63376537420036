import React from "react"
import { Input, LabelModel, CardContainer } from "./requestElements"
import Grid from "@mui/material/Grid"

const SenderInfo = (props) => {
  return (
    <CardContainer>
      <Grid
        container
        rows={{ md: 12 }}
        justifyContent="flex-start"
        mb={2}
        alignItems="center"
      >
        <Grid item xs={2}>
          <LabelModel>ชื่อบริษัท *</LabelModel>
        </Grid>
        <Grid item xs={4}>
          <Input
            type="text"
            placeholder="รายละเอียด"
            name="organizeName"
            onChange={props.handleProduct}
            value={props.isProduct.organizeName}
            disabled
          />
        </Grid>
        <Grid item xs={2}>
          <LabelModel>ผู้ติดต่อ *</LabelModel>
        </Grid>
        <Grid item xs={4}>
          <Input
            type="text"
            placeholder="รายละเอียด"
            name="contactName"
            onChange={props.handleProduct}
            value={props.isProduct.contactName}
            disabled={props.isProduct.status !== "sendQuotation"}
          />
        </Grid>
      </Grid>
      <Grid
        container
        rows={{ md: 12 }}
        justifyContent="flex-start"
        mb={2}
        alignItems="center"
      >
        <Grid item xs={2}>
          <LabelModel>สาขาที่ *</LabelModel>
        </Grid>
        <Grid item xs={4}>
          <Input
            type="text"
            placeholder="รายละเอียด"
            name="organizeBranch"
            onChange={props.handleProduct}
            value={props.isProduct.organizeBranch}
            disabled
          />
        </Grid>
        <Grid item xs={2}>
          <LabelModel>เบอร์โทร *</LabelModel>
        </Grid>
        <Grid item xs={4}>
          <Input
            type="text"
            placeholder="รายละเอียด"
            name="phoneNumber"
            onChange={props.handleProduct}
            value={props.isProduct.phoneNumber}
            disabled={props.isProduct.status !== "sendQuotation"}
          />
        </Grid>
      </Grid>
      <Grid
        container
        rows={{ md: 12 }}
        justifyContent="flex-start"
        mb={2}
        alignItems="center"
      >
        <Grid item xs={2}>
          <LabelModel>หมายเลขกำกับภาษี</LabelModel>
        </Grid>
        <Grid item xs={4}>
          <Input
            type="text"
            placeholder="รายละเอียด"
            name="taxId"
            onChange={props.handleProduct}
            value={props.isProduct.taxId}
            disabled
          />
        </Grid>
        <Grid item xs={2}>
          <LabelModel>FAX</LabelModel>
        </Grid>
        <Grid item xs={4}>
          <Input
            type="text"
            placeholder="รายละเอียด"
            name="faxNumber"
            onChange={props.handleProduct}
            value={props.isProduct.faxNumber}
            disabled={props.isProduct.status !== "sendQuotation"}
          />
        </Grid>
      </Grid>
      <Grid
        container
        rows={{ md: 12 }}
        justifyContent="flex-start"
        mb={2}
        alignItems="center"
      >
        <Grid item xs={2}>
          <LabelModel>ที่อยู่ *</LabelModel>
        </Grid>
        <Grid item xs={4}>
          <Input
            type="text"
            placeholder="รายละเอียด"
            name="address"
            onChange={props.handleProduct}
            value={props.isProduct.address}
            disabled
          />
        </Grid>
        <Grid item xs={2}>
          <LabelModel>Email *</LabelModel>
        </Grid>
        <Grid item xs={4}>
          <Input
            type="text"
            placeholder="รายละเอียด"
            name="email"
            onChange={props.handleProduct}
            value={props.isProduct.email}
            disabled={props.isProduct.status !== "sendQuotation"}
          />
        </Grid>
      </Grid>
      <Grid
        container
        rows={{ md: 12 }}
        justifyContent="flex-start"
        mb={2}
        alignItems="center"
      >
        <Grid item xs={2}>
          <LabelModel>รหัสไปรษณีย์ *</LabelModel>
        </Grid>
        <Grid item xs={4}>
          <Input
            type="text"
            placeholder="รายละเอียด"
            name="postcode"
            onChange={props.handleProduct}
            value={props.isProduct.postcode}
            disabled
          />
        </Grid>
        <Grid item xs={2}>
          <LabelModel></LabelModel>
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>
    </CardContainer>
  )
}

export default SenderInfo
