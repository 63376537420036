import React, { useState, useEffect } from "react"
import useForm from "./useForm"
import apiService from "../../api/axios"
import {
  HeroContainer,
  RowContainer,
  TableCard,
  TableText,
  HeadCard,
  Cardcolumn,
  InputRow,
  LabelModel,
  SelectStatus,
  HeadRow,
  BtnNext,
  BtnPrev,
  BeforeRow,
  BtnRow,
} from "./requestElements"
import PropTypes from "prop-types"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import SenderInfo from "./SenderInfo"
import Service from "./Service"
import Product from "./Product"
import OtherInfo from "./OhterInfo"
import { useNavigate } from "react-router-dom"

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}
const select = [
  "sendQuotation",
  "jobApprove",
  "sampleSubmisstion",
  "testing",
  "checkTheResult",
  "testSuccessful",
  "invoice",
  "sendResult",
  "complete",
]

const Request = () => {
  const navigate = useNavigate()
  const [, setCurrentUser] = useState(undefined)
  const [value, setValue] = React.useState(0)
  useEffect(() => {
    const user = apiService.getCurrentUser()
    if (user) {
      setCurrentUser(user)
    } else {
      apiService.logout()
      navigate("/")
    }
  }, [navigate])
  const {
    isService,
    isProduct,
    isStatus,
    subServices,
    isUncertainty,
    isSamplePicture,
    isSampleReturn,
    handleDownloadPDF,
    setSubServices,
    handleDownloadCSV,
    setSampleReturn,
    setSamplePicture,
    setUncertainty,
    handleProduct,
    handleChangeStatus,
    updateForm,
    setService,
    updateFormStatus,
    updateSubServiceStatus,
  } = useForm()

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const [isMask01, setMask01] = useState(false)
  const [isMask02, setMask02] = useState(false)
  const [isMask03, setMask03] = useState(false)
  const [isMask04, setMask04] = useState(false)
  const [isMask05, setMask05] = useState(false)
  const [isN9501, setN9501] = useState(false)
  const [isN9502, setN9502] = useState(false)
  const [isN9503, setN9503] = useState(false)
  const [isN9504, setN9504] = useState(false)
  const [isAnsi01, setAnsi01] = useState(false)
  const [isAnsi02, setAnsi02] = useState(false)
  const [isAnsi03, setAnsi03] = useState(false)
  const [isAnsi04, setAnsi04] = useState(false)
  const [isEn01, setEn01] = useState(false)
  const [isEn02, setEn02] = useState(false)
  const [isEn03, setEn03] = useState(false)
  const [isEn04, setEn04] = useState(false)
  const [isEn05, setEn05] = useState(false)
  const [isEn06, setEn06] = useState(false)
  const [isEn07, setEn07] = useState(false)
  const [isEn08, setEn08] = useState(false)
  const [isEn09, setEn09] = useState(false)
  const [isEn10, setEn10] = useState(false)
  useEffect(() => {
    if (isProduct.mask01 === "01") {
      setMask01(true)
    }
    if (isProduct.mask02 === "02") {
      setMask02(true)
    }
    if (isProduct.mask03 === "03") {
      setMask03(true)
    }
    if (isProduct.mask04 === "04") {
      setMask04(true)
    }
    if (isProduct.mask05 === "05") {
      setMask05(true)
    }
    if (isProduct.n9501 === "01") {
      setN9501(true)
    }
    if (isProduct.n9502 === "02") {
      setN9502(true)
    }
    if (isProduct.n9503 === "03") {
      setN9503(true)
    }
    if (isProduct.n9504 === "04") {
      setN9504(true)
    }
    if (isProduct.ansi01 === "01") {
      setAnsi01(true)
    }
    if (isProduct.ansi02 === "02") {
      setAnsi02(true)
    }
    if (isProduct.ansi03 === "03") {
      setAnsi03(true)
    }
    if (isProduct.ansi04 === "04") {
      setAnsi04(true)
    }
    if (isProduct.en01 === "01") {
      setEn01(true)
    }
    if (isProduct.en02 === "02") {
      setEn02(true)
    }
    if (isProduct.en03 === "03") {
      setEn03(true)
    }
    if (isProduct.en04 === "04") {
      setEn04(true)
    }
    if (isProduct.en05 === "05") {
      setEn05(true)
    }
    if (isProduct.en06 === "06") {
      setEn06(true)
    }
    if (isProduct.en07 === "07") {
      setEn07(true)
    }
    if (isProduct.en08 === "08") {
      setEn08(true)
    }
    if (isProduct.en09 === "09") {
      setEn09(true)
    }
    if (isProduct.en10 === "10") {
      setEn10(true)
    }
  }, [
    isProduct.ansi01,
    isProduct.ansi02,
    isProduct.ansi03,
    isProduct.ansi04,
    isProduct.en01,
    isProduct.en02,
    isProduct.en03,
    isProduct.en04,
    isProduct.en05,
    isProduct.en06,
    isProduct.en07,
    isProduct.en08,
    isProduct.en09,
    isProduct.en10,
    isProduct.mask01,
    isProduct.mask02,
    isProduct.mask03,
    isProduct.mask04,
    isProduct.mask05,
    isProduct.n9501,
    isProduct.n9502,
    isProduct.n9503,
    isProduct.n9504,
  ])
  return (
    <div>
      <HeroContainer>
        <RowContainer>
          <HeadCard>
            <HeadRow>
              <TableText>รายการสิ่งทดสอบ</TableText>
              <BtnNext onClick={handleDownloadPDF}>แบบบันทึกคำขอ</BtnNext>
              <BtnNext onClick={handleDownloadCSV}>ใบเสนอราคา</BtnNext>
              <InputRow>
                <LabelModel>สถานะ</LabelModel>
                <SelectStatus value={isStatus} onChange={handleChangeStatus}>
                  {select.map((select) => (
                    <option key={select} value={select}>
                      {select}
                    </option>
                  ))}
                </SelectStatus>
              </InputRow>
            </HeadRow>
          </HeadCard>
        </RowContainer>
        <Cardcolumn>
          <TableCard>
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  variant="fullWidth"
                >
                  <Tab
                    label="ข้อมูลผู้ส่งทดสอบ"
                    {...a11yProps(0)}
                    sx={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      fontFamily: "kanit",
                    }}
                  />
                  <Tab
                    label="การบริการ"
                    {...a11yProps(1)}
                    sx={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      fontFamily: "kanit",
                    }}
                  />
                  <Tab
                    label="ชิ้นงานส่งทดสอบ"
                    {...a11yProps(2)}
                    sx={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      fontFamily: "kanit",
                    }}
                  />
                  <Tab
                    label="ข้อมูลเพิ่มเติม"
                    {...a11yProps(3)}
                    sx={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      fontFamily: "kanit",
                    }}
                  />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <SenderInfo
                  isProduct={isProduct}
                  handleProduct={handleProduct}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Service
                  isProduct={isProduct}
                  isService={isService}
                  subServices={subServices}
                  setService={setService}
                  handleProduct={handleProduct}
                  isMask01={isMask01}
                  setMask01={setMask01}
                  isMask02={isMask02}
                  setMask02={setMask02}
                  isMask03={isMask03}
                  setMask03={setMask03}
                  isMask04={isMask04}
                  setMask04={setMask04}
                  isMask05={isMask05}
                  setMask05={setMask05}
                  isN9501={isN9501}
                  setN9501={setN9501}
                  isN9502={isN9502}
                  setN9502={setN9502}
                  isN9503={isN9503}
                  setN9503={setN9503}
                  isN9504={isN9504}
                  setN9504={setN9504}
                  isAnsi01={isAnsi01}
                  setAnsi01={setAnsi01}
                  isAnsi02={isAnsi02}
                  setAnsi02={setAnsi02}
                  isAnsi03={isAnsi03}
                  setAnsi03={setAnsi03}
                  isAnsi04={isAnsi04}
                  setAnsi04={setAnsi04}
                  isEn01={isEn01}
                  setEn01={setEn01}
                  isEn02={isEn02}
                  setEn02={setEn02}
                  isEn03={isEn03}
                  setEn03={setEn03}
                  isEn04={isEn04}
                  setEn04={setEn04}
                  isEn05={isEn05}
                  setEn05={setEn05}
                  isEn06={isEn06}
                  setEn06={setEn06}
                  isEn07={isEn07}
                  setEn07={setEn07}
                  isEn08={isEn08}
                  setEn08={setEn08}
                  isEn09={isEn09}
                  setEn09={setEn09}
                  isEn10={isEn10}
                  setEn10={setEn10}
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Product
                  isProduct={isProduct}
                  subServices={subServices}
                  setSubServices={setSubServices}
                />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <OtherInfo
                  isProduct={isProduct}
                  isUncertainty={isUncertainty}
                  isSamplePicture={isSamplePicture}
                  handleProduct={handleProduct}
                  setSamplePicture={setSamplePicture}
                  setUncertainty={setUncertainty}
                  isSampleReturn={isSampleReturn}
                  setSampleReturn={setSampleReturn}
                />
              </TabPanel>
            </Box>
          </TableCard>
          <BeforeRow>
            <TableText></TableText>
            <BtnRow>
              <BtnPrev onClick={() => navigate(-1)}>ย้อนกลับ</BtnPrev>
              {isProduct.status === "sendQuotation" ? (
                <BtnNext onClick={updateForm}>บันทึก</BtnNext>
              ) : isProduct.status === "jobApprove" ? (
                <BtnNext onClick={updateSubServiceStatus}>บันทึก</BtnNext>
              ) : (
                <BtnNext onClick={updateFormStatus}>บันทึก</BtnNext>
              )}
            </BtnRow>
          </BeforeRow>
        </Cardcolumn>
      </HeroContainer>
    </div>
  )
}

export default Request
