import React from "react"
import {
  LabelModel,
  RadioCheck,
  LabelHead,
  Inputcolumn,
  CardContainer,
} from "./requestElements"
import Grid from "@mui/material/Grid"

const OhterInfo = (props) => {
  return (
    <div>
      <CardContainer>
        <Inputcolumn>
          <Grid container spacing={{ md: 4 }} columns={{ md: 12 }}>
            <Grid
              container
              rows={{ md: 12 }}
              justifyContent="flex-start"
              mb={2}
              alignItems="center"
            >
              <Grid item xs={3}>
                <LabelHead>ประเภทการทดสอบ</LabelHead>
              </Grid>
              <Grid item>
                <RadioCheck
                  type="radio"
                  name="testType"
                  value="regular"
                  checked={props.isProduct.testType === "regular"}
                  onChange={props.handleProduct}
                  disabled={props.isProduct.status !== "sendQuotation"}
                />
              </Grid>
              <Grid item xs={3}>
                <LabelModel>Regular (14 Working Days)</LabelModel>
              </Grid>
              <Grid item>
                <RadioCheck
                  type="radio"
                  name="testType"
                  value="express"
                  checked={props.isProduct.testType === "express"}
                  onChange={props.handleProduct}
                  disabled={props.isProduct.status !== "sendQuotation"}
                />
              </Grid>
              <Grid item xs={4}>
                <LabelModel>Express (7 Working Days, 20% Surcharge)</LabelModel>
              </Grid>
            </Grid>
            <Grid
              container
              rows={{ md: 12 }}
              justifyContent="flex-start"
              mb={2}
              alignItems="center"
            >
              <Grid item xs={3}>
                <LabelHead>ประเภทใบรายงานผล</LabelHead>
              </Grid>
              <Grid item>
                <RadioCheck
                  type="radio"
                  name="reportType"
                  value="English"
                  checked={props.isProduct.reportType === "English"}
                  onChange={props.handleProduct}
                  disabled={props.isProduct.status !== "sendQuotation"}
                />
              </Grid>
              <Grid item xs={3}>
                <LabelModel>English</LabelModel>
              </Grid>
              <Grid item>
                <RadioCheck
                  type="radio"
                  name="reportType"
                  value="Thai"
                  checked={props.isProduct.reportType === "Thai"}
                  onChange={props.handleProduct}
                  disabled={props.isProduct.status !== "sendQuotation"}
                />
              </Grid>
              <Grid item xs={4}>
                <LabelModel>ไทย</LabelModel>
              </Grid>
            </Grid>
            <Grid
              container
              rows={{ md: 12 }}
              justifyContent="flex-start"
              mb={2}
              alignItems="center"
            >
              <Grid item xs={3}>
                <LabelHead>การรับผลทดสอบ</LabelHead>
              </Grid>
              <Grid item>
                <RadioCheck
                  type="radio"
                  name="receivingResult"
                  value="bySelf"
                  checked={props.isProduct.receivingResult === "bySelf"}
                  onChange={props.handleProduct}
                  disabled={props.isProduct.status !== "sendQuotation"}
                />
              </Grid>
              <Grid item xs={3}>
                <LabelModel>รับด้วยตัวเอง (By self)</LabelModel>
              </Grid>
              <Grid item>
                <RadioCheck
                  type="radio"
                  name="receivingResult"
                  value="postoffice"
                  checked={props.isProduct.receivingResult === "postoffice"}
                  onChange={props.handleProduct}
                  disabled={props.isProduct.status !== "sendQuotation"}
                />
              </Grid>
              <Grid item xs={2}>
                <LabelModel>ไปรษณีย์</LabelModel>
              </Grid>
              <Grid item>
                <RadioCheck
                  type="radio"
                  name="receivingResult"
                  value="email"
                  checked={props.isProduct.receivingResult === "email"}
                  onChange={props.handleProduct}
                  disabled={props.isProduct.status !== "sendQuotation"}
                />
              </Grid>
              <Grid item xs={2}>
                <LabelModel>Email</LabelModel>
              </Grid>
            </Grid>
            <Grid
              container
              rows={{ md: 12 }}
              justifyContent="flex-start"
              mb={2}
              alignItems="center"
            >
              <Grid item xs={3}>
                <LabelHead>รูปภาพตัวอย่าง</LabelHead>
              </Grid>
              <Grid item>
                <RadioCheck
                  type="radio"
                  name="isSamplePicture"
                  value="true"
                  checked={props.isSamplePicture === true}
                  onChange={() => props.setSamplePicture(true)}
                  disabled={props.isProduct.status !== "sendQuotation"}
                />
              </Grid>
              <Grid item xs={3}>
                <LabelModel>ต้องการ</LabelModel>
              </Grid>
              <Grid item>
                <RadioCheck
                  type="radio"
                  name="isSamplePicture"
                  value="false"
                  checked={props.isSamplePicture === false}
                  onChange={() => props.setSamplePicture(false)}
                  disabled={props.isProduct.status !== "sendQuotation"}
                />
              </Grid>
              <Grid item xs={4}>
                <LabelModel>ไม่ต้องการ</LabelModel>
              </Grid>
            </Grid>
            <Grid
              container
              rows={{ md: 12 }}
              justifyContent="flex-start"
              mb={2}
              alignItems="center"
            >
              <Grid item xs={3}>
                <LabelHead>ค่าความไม่แน่นอน</LabelHead>
              </Grid>
              <Grid item>
                <RadioCheck
                  type="radio"
                  name="isUncertainty"
                  value="true"
                  checked={props.isUncertainty === true}
                  onChange={() => props.setUncertainty(true)}
                  disabled={props.isProduct.status !== "sendQuotation"}
                />
              </Grid>
              <Grid item xs={3}>
                <LabelModel>ต้องการ</LabelModel>
              </Grid>
              <Grid item>
                <RadioCheck
                  type="radio"
                  name="isUncertainty"
                  value="false"
                  checked={props.isUncertainty === false}
                  onChange={() => props.setUncertainty(false)}
                  disabled={props.isProduct.status !== "sendQuotation"}
                />
              </Grid>
              <Grid item xs={4}>
                <LabelModel>ไม่ต้องการ</LabelModel>
              </Grid>
            </Grid>
            <Grid
              container
              rows={{ md: 12 }}
              justifyContent="flex-start"
              mb={2}
              alignItems="center"
            >
              <Grid item xs={3}>
                <LabelHead>การรับตัวอย่างคืน</LabelHead>
              </Grid>
              <Grid item>
                <RadioCheck
                  type="radio"
                  name="isSampleReturn"
                  value="return"
                  checked={props.isSampleReturn === "return"}
                  onChange={() => props.setSampleReturn("return")}
                  disabled={props.isProduct.status !== "sendQuotation"}
                />
              </Grid>
              <Grid item xs={3}>
                <LabelModel>ขอรับคืน(ภายใน xx)</LabelModel>
              </Grid>
              <Grid item>
                <RadioCheck
                  type="radio"
                  name="isSampleReturn"
                  value="destroy"
                  checked={props.isSampleReturn === "destroy"}
                  onChange={() => props.setSampleReturn("destroy")}
                  disabled={props.isProduct.status !== "sendQuotation"}
                />
              </Grid>
              <Grid item xs={4}>
                <LabelModel>ไม่ขอรับคืน </LabelModel>
              </Grid>
            </Grid>
          </Grid>
        </Inputcolumn>
      </CardContainer>
    </div>
  )
}

export default OhterInfo
