import React from "react"
import { SelectStatusSub } from "./requestElements"

const selecOption = ["pending", "testing", "recheck", "complete"]

const Select = (props) => {
  const { setSubServices, row, subServices } = props
  const { _id, status } = row

  const handleChange = (value) => {
    let newData = subServices.map((e) => {
      if (e._id === _id) {
        return { ...e, status: value }
      } else return e
    })
    setSubServices(newData)
  }

  return (
    <div>
      <SelectStatusSub
        value={status}
        onChange={(e) => handleChange(e.target.value)}
        disabled={props.isProduct.status === "sendQuotation"}
      >
        {selecOption.map((select) => (
          <option key={select} value={select}>
            {select}
          </option>
        ))}
      </SelectStatusSub>
    </div>
  )
}

export default Select
