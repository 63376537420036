import styled from "styled-components"
import { Link as LinkR } from "react-router-dom"

export const NavIcon = styled.div`
  margin-left: 2rem;
  font-size: 2rem;
  height: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const SidebarNav = styled.nav`
  background: #fff;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  top: 0;
  left: 0;
  transition: 350ms;
  z-index: 10;
`

export const SidebarWrap = styled.div`
  width: 100%;
`
export const HeadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const SidebarLink = styled(LinkR)`
  display: flex;
  color: #000;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 40px;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  .active {
    background-color: #343891;
  }
  &:hover {
    background-color: #343891;
  }
`

export const SidebarLabel = styled.span`
  margin-left: 16px;
`
export const SidebarData = [
  {
    name: "คำขอสมาชิก",
    path: "/index",
  },
  {
    name: "คำขอส่งทดสอบ",
    path: "/request",
  },
]
