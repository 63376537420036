import React from "react"
import {
  RowContainerInput,
  LabelModel,
  RadioCheck,
  RadioRow,
  LabelHead,
  RowSubservice,
} from "./requestElements"
import "./Styles.css"

const Service = (props) => {
  return (
    <div>
      <RowContainerInput>
        <LabelHead>ชนิดของสินค้าที่ส่งทดสอบ</LabelHead>
      </RowContainerInput>
      <RowContainerInput>
        <RadioRow>
          <RadioCheck
            type="radio"
            name="isService"
            value="M"
            checked={props.isService === "M"}
            onChange={() => props.setService("M")}
            disabled={props.isProduct.status !== "sendQuotation"}
          />
          <LabelModel>หน้ากากอนามันทางการแพทย์</LabelModel>
        </RadioRow>
        <RadioRow>
          <RadioCheck
            type="radio"
            name="isService"
            value="N"
            checked={props.isService === "N"}
            onChange={() => props.setService("N")}
            disabled={props.isProduct.status !== "sendQuotation"}
          />
          <LabelModel>N95</LabelModel>
        </RadioRow>
        <RadioRow>
          <RadioCheck
            type="radio"
            name="isService"
            value="A"
            checked={props.isService === "A"}
            onChange={() => props.setService("A")}
            disabled={props.isProduct.status !== "sendQuotation"}
          />
          <LabelModel>PPE(ANSI)</LabelModel>
        </RadioRow>
        <RadioRow>
          <RadioCheck
            type="radio"
            name="isService"
            value="E"
            checked={props.isService === "E"}
            onChange={() => props.setService("E")}
            disabled={props.isProduct.status !== "sendQuotation"}
          />
          <LabelModel>PPE(EN13975)</LabelModel>
        </RadioRow>
      </RowContainerInput>
      <hr />
      {props.isService === "M" ? (
        <div>
          <RowContainerInput>
            <LabelHead>
              การทดสอบหน้ากากอนามัยทางการแพทย์ (TIS 2424-2562)
            </LabelHead>
          </RowContainerInput>
          <div>
            <label className="container">
              การทดสอบการลามไฟ (Flammability Test) (ASTM F2100 (16CFR part
              1610))
              <input
                type="checkbox"
                name="mask01"
                value={
                  props.isMask01 === true
                    ? (props.isProduct.mask01 = "01")
                    : (props.isProduct.mask01 = "")
                }
                checked={props.isMask01 === true}
                onChange={() => props.setMask01(!props.isMask01)}
                disabled={props.isProduct.status !== "sendQuotation"}
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div>
            <label className="container">
              การวิเคราะห์ทดสอบความต้านของเหลวซึมผ่าน ความดันต่ำสุด (Penetration
              by Synthetic Blood) (ASTM F1862-17)
              <input
                type="checkbox"
                name="mask02"
                value={
                  props.isMask02 === true
                    ? (props.isProduct.mask02 = "02")
                    : (props.isProduct.mask02 = "")
                }
                checked={props.isMask02 === true}
                onChange={() => props.setMask02(!props.isMask02)}
                disabled={props.isProduct.status !== "sendQuotation"}
              />
              <span className="checkmark"></span>
            </label>
          </div>
          {props.isMask02 === true ? (
            <>
              <RowSubservice>
                <RadioRow>
                  <RadioCheck
                    type="radio"
                    name="option"
                    value="80"
                    checked={props.isProduct.option === "80"}
                    onChange={props.handleProduct}
                    disabled={props.isProduct.status !== "sendQuotation"}
                  />
                  <LabelModel>80 mmHg</LabelModel>
                </RadioRow>
                <RadioRow>
                  <RadioCheck
                    type="radio"
                    name="option"
                    value="120"
                    checked={props.isProduct.option === "120"}
                    onChange={props.handleProduct}
                    disabled={props.isProduct.status !== "sendQuotation"}
                  />
                  <LabelModel>120 mmHg</LabelModel>
                </RadioRow>
                <RadioRow>
                  <RadioCheck
                    type="radio"
                    name="option"
                    value="160"
                    checked={props.isProduct.option === "160"}
                    onChange={props.handleProduct}
                    disabled={props.isProduct.status !== "sendQuotation"}
                  />
                  <LabelModel>160 mmHg</LabelModel>
                </RadioRow>
              </RowSubservice>
            </>
          ) : null}
          <div>
            <label className="container">
              การวิเคราะห์ทดสอบความแตกต่างของความดัน (∆P/Differential Pressure)
              (BS EN 14683: 2019, Annex C)
              <input
                type="checkbox"
                name="mask03"
                value={
                  props.isMask03 === true
                    ? (props.isProduct.mask03 = "03")
                    : (props.isProduct.mask03 = "")
                }
                checked={props.isMask03 === true}
                onChange={() => props.setMask03(!props.isMask03)}
                disabled={props.isProduct.status !== "sendQuotation"}
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div>
            <label className="container">
              การวิเคราะห์ทดสอบประสิทธิภาพการกรองอนุภาคของหน้ากากอนามัยขนาด 0.1
              ไมครอน (Particulate Filtration efficiency 0.1 µm. (ASTM F2299)
              <input
                type="checkbox"
                name="mask04"
                value={
                  props.isMask04 === true
                    ? (props.isProduct.mask04 = "04")
                    : (props.isProduct.mask04 = "")
                }
                checked={props.isMask04 === true}
                onChange={() => props.setMask04(!props.isMask04)}
                disabled={props.isProduct.status !== "sendQuotation"}
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div>
            <label className="container">
              การวิเคราะห์ทดสอบประสิทธิภาพการกรองแบคทีเรีย (Bacterial Filtration
              Efficiency) (BS EN 14683: 2019, Annex B and ASTM F2101:19)
              <input
                type="checkbox"
                name="mask05"
                value={
                  props.isMask05 === true
                    ? (props.isProduct.mask05 = "05")
                    : (props.isProduct.mask05 = "")
                }
                checked={props.isMask05 === true}
                onChange={() => props.setMask05(!props.isMask05)}
                disabled={props.isProduct.status !== "sendQuotation"}
              />
              <span className="checkmark"></span>
            </label>
          </div>
        </div>
      ) : props.isService === "N" ? (
        <div>
          <RowContainerInput>
            <LabelHead>การทดสอบหน้ากากอนามัย N95 (TIS 2480-2562)</LabelHead>
          </RowContainerInput>
          <div>
            <label className="container">
              การวิเคราะห์ทดสอบความต้านของเหลวซึมผ่าน ความดันต่ำสุด (Penetration
              by Synthetic Blood) (ASTM F1862-17)
              <input
                type="checkbox"
                name="n9501"
                value={
                  props.isN9501 === true
                    ? (props.isProduct.n9501 = "01")
                    : (props.isProduct.n9501 = "")
                }
                checked={props.isN9501 === true}
                onChange={() => props.setN9501(!props.isN9501)}
                disabled={props.isProduct.status !== "sendQuotation"}
              />
              <span className="checkmark"></span>
            </label>
          </div>
          {props.isN9501 === true ? (
            <>
              <RowSubservice>
                <RadioRow>
                  <RadioCheck
                    type="radio"
                    name="option"
                    value="80"
                    checked={props.isProduct.option === "80"}
                    onChange={props.handleProduct}
                    disabled={props.isProduct.status !== "sendQuotation"}
                  />
                  <LabelModel>80 mmHg</LabelModel>
                </RadioRow>
                <RadioRow>
                  <RadioCheck
                    type="radio"
                    name="option"
                    value="120"
                    checked={props.isProduct.option === "120"}
                    onChange={props.handleProduct}
                    disabled={props.isProduct.status !== "sendQuotation"}
                  />
                  <LabelModel>120 mmHg</LabelModel>
                </RadioRow>
                <RadioRow>
                  <RadioCheck
                    type="radio"
                    name="option"
                    value="80"
                    checked={props.isProduct.option === "160"}
                    onChange={props.handleProduct}
                    disabled={props.isProduct.status !== "sendQuotation"}
                  />
                  <LabelModel>160 mmHg</LabelModel>
                </RadioRow>
              </RowSubservice>
            </>
          ) : null}
          <div>
            <label className="container">
              การวิเคราะห์ทดสอบความแตกต่างของความดัน (∆P/Differential Pressure)
              (BS EN 14683: 2019, Annex C)
              <input
                type="checkbox"
                name="n9502"
                value={
                  props.isN9502 === true
                    ? (props.isProduct.n9502 = "02")
                    : (props.isProduct.n9502 = "")
                }
                checked={props.isN9502 === true}
                onChange={() => props.setN9502(!props.isN9502)}
                disabled={props.isProduct.status !== "sendQuotation"}
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div>
            <label className="container">
              การวิเคราะห์ทดสอบประสิทธิภาพในการกรองอนุภาคขนาด 0.3 ไมครอน (MMAD)
              (Particulate Filtration efficiency 0.3 µm.) (42 CFR 84)
              <input
                type="checkbox"
                name="n9503"
                value={
                  props.isN9503 === true
                    ? (props.isProduct.n9503 = "03")
                    : (props.isProduct.n9503 = "")
                }
                checked={props.isN9503 === true}
                onChange={() => props.setN9503(!props.isN9503)}
                disabled={props.isProduct.status !== "sendQuotation"}
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div>
            <label className="container">
              การทดสอบการลามไฟ (Flammability Test) (ASTM F2100 (16CFR part
              1610))
              <input
                type="checkbox"
                name="n9504"
                value={
                  props.isN9504 === true
                    ? (props.isProduct.n9504 = "04")
                    : (props.isProduct.n9504 = "")
                }
                checked={props.isN9501 === true}
                onChange={() => props.setN9504(!props.isN9504)}
                disabled={props.isProduct.status !== "sendQuotation"}
              />
              <span className="checkmark"></span>
            </label>
          </div>
        </div>
      ) : props.isService === "A" ? (
        <div>
          <RowContainerInput>
            <LabelHead>การทดสอบ PPE (ANSI)</LabelHead>
          </RowContainerInput>
          <div>
            <label className="container">
              การวิเคราะห์ทดสอบความสามารถต้านแรงดันน้ำทะลุผ่านหรือการซึมผ่านของของเหลว
              (Hydrostatic Pressure) (AATCC 127 or ISO 811)
              <input
                type="checkbox"
                name="ansi01"
                value={
                  props.isAnsi01 === true
                    ? (props.isProduct.ansi01 = "01")
                    : (props.isProduct.ansi01 = "")
                }
                checked={props.isAnsi01 === true}
                onChange={() => props.setAnsi01(!props.isAnsi01)}
                disabled={props.isProduct.status !== "sendQuotation"}
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div>
            <label className="container">
              การวิเคราะห์ทดสอบความสามารถในการทะลุผ่านของน้ำ (Impact
              penetration) (AATCC 42)
              <input
                type="checkbox"
                name="ansi02"
                value={
                  props.isAnsi02 === true
                    ? (props.isProduct.ansi02 = "02")
                    : (props.isProduct.ansi02 = "")
                }
                checked={props.isAnsi02 === true}
                onChange={() => props.setAnsi02(!props.isAnsi02)}
                disabled={props.isProduct.status !== "sendQuotation"}
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div>
            <label className="container">
              การวิเคราะห์ทดสอบความสามารถในการป้องกันไวรัส (Viral penetration)
              (ASTM F1671)
              <input
                type="checkbox"
                name="ansi03"
                value={
                  props.isAnsi03 === true
                    ? (props.isProduct.ansi03 = "03")
                    : (props.isProduct.ansi03 = "")
                }
                checked={props.isAnsi03 === true}
                onChange={() => props.setAnsi03(!props.isAnsi03)}
                disabled={props.isProduct.status !== "sendQuotation"}
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div>
            <label className="container">
              การวิเคราะห์ทดสอบความสามารถในการป้องกันเลือด (Synthetic blood
              penetration) (ASTM F1670)
              <input
                type="checkbox"
                name="ansi04"
                value={
                  props.isAnsi04 === true
                    ? (props.isProduct.ansi04 = "04")
                    : (props.isProduct.ansi04 = "")
                }
                checked={props.isAnsi04 === true}
                onChange={() => props.setAnsi04(!props.isAnsi04)}
                disabled={props.isProduct.status !== "sendQuotation"}
              />
              <span className="checkmark"></span>
            </label>
          </div>
        </div>
      ) : (
        <div>
          <RowContainerInput>
            <LabelHead>การทดสอบ PPE (EN13975)</LabelHead>
          </RowContainerInput>
          <div>
            <label className="container">
              การวิเคราะห์ทดสอบความสามารถต้านแรงดันน้ำทะลุผ่านหรือการซึมผ่านของของเหลว
              (Hydrostatic Pressure) (AATCC 127 or ISO 811)
              <input
                type="checkbox"
                name="en01"
                value={
                  props.isEn01 === true
                    ? (props.isProduct.en01 = "01")
                    : (props.isProduct.en01 = "")
                }
                checked={props.isEn01 === true}
                onChange={() => props.setEn01(!props.isEn01)}
                disabled={props.isProduct.status !== "sendQuotation"}
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div>
            <label className="container">
              การวิเคราะห์ทดสอบการซึมผ่านของจุลินทรีย์ในสภาพแห้ง (Resistance to
              dry microbial penetration) (ISO 22612)
              <input
                type="checkbox"
                name="en02"
                value={
                  props.isEn02 === true
                    ? (props.isProduct.en02 = "02")
                    : (props.isProduct.en02 = "")
                }
                checked={props.isEn02 === true}
                onChange={() => props.setEn02(!props.isEn02)}
                disabled={props.isProduct.status !== "sendQuotation"}
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div>
            <label className="container">
              การวิเคราะห์ทดสอบการซึมผ่านของจุลินทรีย์ในสภาพเปียก (Resistance to
              wet microbial penetration) (ISO 22610)
              <input
                type="checkbox"
                name="en03"
                value={
                  props.isEn03 === true
                    ? (props.isProduct.en03 = "03")
                    : (props.isProduct.en03 = "")
                }
                checked={props.isEn03 === true}
                onChange={() => props.setEn03(!props.isEn03)}
                disabled={props.isProduct.status !== "sendQuotation"}
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div>
            <label className="container">
            การวิเคราะห์ทดสอบการปราศจากเชื้อหรือปริมาณที่มีอยู่ (Microbial Cleanliness) (ISO 11737-1)
              <input
                type="checkbox"
                name="en04"
                value={
                  props.isEn04 === true
                    ? (props.isProduct.en04 = "04")
                    : (props.isProduct.en04 = "")
                }
                checked={props.isEn04 === true}
                onChange={() => props.setEn04(!props.isEn04)}
                disabled={props.isProduct.status !== "sendQuotation"}
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div>
            <label className="container">
              การวิเคราะห์ทดสอบการทนแรงระเบิดสภาพแห้ง (Bursting strength and
              bursting distension - dry) (ISO 13938-1)
              <input
                type="checkbox"
                name="en05"
                value={
                  props.isEn05 === true
                    ? (props.isProduct.en05 = "05")
                    : (props.isProduct.en05 = "")
                }
                checked={props.isEn05 === true}
                onChange={() => props.setEn05(!props.isEn05)}
                disabled={props.isProduct.status !== "sendQuotation"}
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div>
            <label className="container">
              การวิเคราะห์ทดสอบการทนแรงระเบิดสภาพเปียก (Bursting strength and
              bursting distension - wet) (ISO 13938-1)
              <input
                type="checkbox"
                name="en06"
                value={
                  props.isEn06 === true
                    ? (props.isProduct.en06 = "06")
                    : (props.isProduct.en06 = "")
                }
                checked={props.isEn06 === true}
                onChange={() => props.setEn06(!props.isEn06)}
                disabled={props.isProduct.status !== "sendQuotation"}
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div>
            <label className="container">
              การวิเคราะห์ทดสอบความต้านแรงดึงในสภาพแห้ง (Tensile strength and
              elongation - dry) (EN 29073-3)
              <input
                type="checkbox"
                name="en07"
                value={
                  props.isEn07 === true
                    ? (props.isProduct.en07 = "07")
                    : (props.isProduct.en07 = "")
                }
                checked={props.isEn07 === true}
                onChange={() => props.setEn07(!props.isEn07)}
                disabled={props.isProduct.status !== "sendQuotation"}
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div>
            <label className="container">
              การวิเคราะห์ทดสอบความต้านแรงดึงในสภาพเปียก (Tensile strength and
              elongation - wet) (EN 29073-3)
              <input
                type="checkbox"
                name="en08"
                value={
                  props.isEn08 === true
                    ? (props.isProduct.en08 = "08")
                    : (props.isProduct.en08 = "")
                }
                checked={props.isEn08 === true}
                onChange={() => props.setEn08(!props.isEn08)}
                disabled={props.isProduct.status !== "sendQuotation"}
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div>
            <label className="container">
              การวิเคราะห์ทดสอบการปลดปล่อยฝุ่น (Particle release test) (ISO
              9073-10)
              <input
                type="checkbox"
                name="en09"
                value={
                  props.isEn09 === true
                    ? (props.isProduct.en09 = "09")
                    : (props.isProduct.en09 = "")
                }
                checked={props.isEn09 === true}
                onChange={() => props.setEn09(!props.isEn09)}
                disabled={props.isProduct.status !== "sendQuotation"}
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div>
            <label className="container">
              การวิเคราะห์ทดสอบการปราศจากเชื้อหรือปริมาณที่มีอยู่ (Microbial
              Cleanliness) (ISO 11737-1)
              <input
                type="checkbox"
                name="en10"
                value={
                  props.isEn10 === true
                    ? (props.isProduct.en10 = "10")
                    : (props.isProduct.en10 = "")
                }
                checked={props.isEn10 === true}
                onChange={() => props.setEn10(!props.isEn10)}
                disabled={props.isProduct.status !== "sendQuotation"}
              />
              <span className="checkmark"></span>
            </label>
          </div>
        </div>
      )}
    </div>
  )
}

export default Service
