import React from "react"
import "./App.css"
import { BrowserRouter as Routers, Routes, Route } from "react-router-dom"
import LoginPage from "./page/login"
import IndexPage from "./page/index"
import RequestPage from "./page/request"
import RequestTablePage from "./page/requestTable"
import DetailsPage from "./page/details"

const App = () => {
  return (
    <Routers>
      <Routes>
        <Route path="/" exact element={<LoginPage />} />
        <Route path="/index" exact element={<IndexPage />} />
        <Route path="/request/:id" exact element={<RequestPage />} />
        <Route path="/request" exact element={<RequestTablePage />} />
        <Route path="/info/:id" exact element={<DetailsPage />} />
      </Routes>
    </Routers>
  )
}

export default App
