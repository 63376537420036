import React, { useState, useEffect } from "react"
import { HeroContainer, RowContainer } from "./indexElements"
import IndexTable from "./IndexTable"
import apiService from "../../api/axios"
import { useNavigate } from "react-router-dom"

const Index = () => {
  const navigate = useNavigate()
  const [, setCurrentUser] = useState(undefined)

  useEffect(() => {
    const user = apiService.getCurrentUser()
    if (user) {
      setCurrentUser(user)
    } else {
      apiService.logout()
      navigate("/")
    }
  }, [navigate])
  return (
    <div>
      <HeroContainer>
        <RowContainer>
          <IndexTable></IndexTable>
        </RowContainer>
      </HeroContainer>
    </div>
  )
}

export default Index
