import React from "react"
import { useNavigate } from "react-router-dom"
import {
  HeroContainer,
  RowContainer,
  Input,
  TableText,
  SelectStatus,
  InputRow,
  FormCard,
  LabelModel,
  CardContainer,
  CardHeadContainer,
  BtnLaod,
  BeforeRow,
  BtnPrev,
  BtnNext,
} from "../indexElements"
import useForm from "./useForm"
import Grid from "@mui/material/Grid"

const select = ["verify", "unverify", "suspended"]
const SendTestForm = () => {
  const navigate = useNavigate()
  const {
    isCustomer,
    isStatus,
    handleCustomer,
    handleChangeStatus,
    updateCustomer,
  } = useForm()

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer")
  }

  return (
    <div>
      <HeroContainer>
        <RowContainer>
          <FormCard>
            <CardHeadContainer>
              <Grid
                container
                spacing={{ md: 4 }}
                rows={{ md: 8 }}
                justifyContent="space-between"
              >
                <TableText>รายละเอียด</TableText>
                <InputRow>
                  <LabelModel>สถานะสมาชิก</LabelModel>
                  <SelectStatus value={isStatus} onChange={handleChangeStatus}>
                    {select.map((select) => (
                      <option key={select} value={select}>
                        {select}
                      </option>
                    ))}
                  </SelectStatus>
                </InputRow>
              </Grid>
            </CardHeadContainer>
            <CardContainer>
              <Grid container spacing={{ md: 4 }} columns={{ md: 12 }}>
                <Grid
                  container
                  rows={{ md: 12 }}
                  justifyContent="flex-start"
                  mb={2}
                  alignItems="center"
                >
                  <Grid item xs={2}>
                    <LabelModel>ชื่อบริษัท *</LabelModel>
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      type="text"
                      placeholder="รายละเอียด"
                      name="organizeName"
                      onChange={handleCustomer}
                      value={isCustomer.organizeName}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <LabelModel>ผู้ติดต่อ *</LabelModel>
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      type="text"
                      placeholder="รายละเอียด"
                      name="contactName"
                      onChange={handleCustomer}
                      value={isCustomer.contactName}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  rows={{ md: 12 }}
                  justifyContent="flex-start"
                  mb={2}
                  alignItems="center"
                >
                  <Grid item xs={2}>
                    <LabelModel>สาขาที่ *</LabelModel>
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      type="text"
                      placeholder="รายละเอียด"
                      name="organizeBranch"
                      onChange={handleCustomer}
                      value={isCustomer.organizeBranch}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <LabelModel>เบอร์โทร *</LabelModel>
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      type="text"
                      placeholder="รายละเอียด"
                      name="phoneNumber"
                      onChange={handleCustomer}
                      value={isCustomer.phoneNumber}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  rows={{ md: 12 }}
                  justifyContent="flex-start"
                  mb={2}
                  alignItems="center"
                >
                  <Grid item xs={2}>
                    <LabelModel>หมายเลขกำกับภาษี</LabelModel>
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      type="text"
                      placeholder="รายละเอียด"
                      name="taxId"
                      onChange={handleCustomer}
                      value={isCustomer.taxId}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <LabelModel>FAX</LabelModel>
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      type="text"
                      placeholder="รายละเอียด"
                      name="faxNumber"
                      onChange={handleCustomer}
                      value={isCustomer.faxNumber}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  rows={{ md: 12 }}
                  justifyContent="flex-start"
                  mb={2}
                  alignItems="center"
                >
                  <Grid item xs={2}>
                    <LabelModel>ที่อยู่ *</LabelModel>
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      type="text"
                      placeholder="รายละเอียด"
                      name="address"
                      onChange={handleCustomer}
                      value={isCustomer.address}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <LabelModel>Email *</LabelModel>
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      type="text"
                      placeholder="รายละเอียด"
                      name="email"
                      onChange={handleCustomer}
                      value={isCustomer.email}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  rows={{ md: 12 }}
                  justifyContent="flex-start"
                  mb={2}
                  alignItems="center"
                >
                  <Grid item xs={2}>
                    <LabelModel>รหัสไปรษณีย์ *</LabelModel>
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      type="text"
                      placeholder="รายละเอียด"
                      name="postcode"
                      onChange={handleCustomer}
                      value={isCustomer.postcode}
                    />
                  </Grid>
                  {isCustomer.certificate !== "" ? (
                    <>
                      <Grid item xs={2}>
                        <LabelModel>หนังสือรับรอง</LabelModel>
                      </Grid>
                      <Grid item xs={4}>
                        <BtnLaod
                          onClick={() => openInNewTab(isCustomer.certificate)}
                        >
                          คลิกเพื่อดูไฟล์แนบ
                        </BtnLaod>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                <Grid
                  container
                  rows={{ md: 12 }}
                  justifyContent="flex-start"
                  mb={2}
                  alignItems="center"
                >
                  <Grid item xs={2}>
                    <LabelModel></LabelModel>
                  </Grid>
                  <Grid item xs={4}></Grid>
                  {isCustomer.vatRegistration !== "" ? (
                    <>
                      <Grid item xs={2}>
                        <LabelModel>กพ. 20</LabelModel>
                      </Grid>
                      <Grid item xs={4}>
                        <BtnLaod
                          onClick={() =>
                            openInNewTab(isCustomer.vatRegistration)
                          }
                        >
                          คลิกเพื่อดูไฟล์แนบ
                        </BtnLaod>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                <Grid
                  container
                  rows={{ md: 12 }}
                  justifyContent="flex-start"
                  mb={2}
                  alignItems="center"
                >
                  <Grid item xs={2}>
                    <LabelModel></LabelModel>
                  </Grid>
                  <Grid item xs={4}></Grid>
                  {isCustomer.other !== "" ? (
                    <>
                      <Grid item xs={2}>
                        <LabelModel>เอกสารอื่นๆ</LabelModel>
                      </Grid>
                      <Grid item xs={4}>
                        <BtnLaod onClick={() => openInNewTab(isCustomer.other)}>
                          คลิกเพื่อดูไฟล์แนบ
                        </BtnLaod>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </Grid>
              <BeforeRow>
                <BtnPrev onClick={() => navigate(-1)}>ย้อนกลับ</BtnPrev>
                <BtnNext onClick={updateCustomer}>บันทึก</BtnNext>
              </BeforeRow>
            </CardContainer>
          </FormCard>
        </RowContainer>
      </HeroContainer>
    </div>
  )
}

export default SendTestForm
