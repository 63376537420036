import React, { useState, useEffect, useCallback } from "react"
import apiService from "../../api/axios"
import { useParams } from "react-router-dom"

const useForm = () => {
  const { id } = useParams()
  const [isStatus, setStatus] = React.useState("")
  const handleChangeStatus = (event) => {
    setStatus(event.target.value)
  }
  const [isService, setService] = useState("")
  const [isSampleReturn, setSampleReturn] = useState("")
  const [isSamplePicture, setSamplePicture] = useState(false)
  const [isUncertainty, setUncertainty] = useState(false)
  const [isProduct, setProduct] = useState({
    id: "",
    jobNumber: "",
    organizeName: "",
    organizeBranch: "",
    status: "",
    taxId: "",
    address: "",
    postcode: "",
    contactName: "",
    phoneNumber: "",
    faxNumber: "",
    email: "",
    service: "",
    productSampleName: "",
    productLotNumber: "",
    productSampleDescription: "",
    reportType: "",
    testType: "",
    receivingResult: "",
    samplePicture: true,
    uncertainty: true,
    sampleReturn: "",
    payment: "",
    option: "",
    mask01: "",
    mask02: "",
    mask03: "",
    mask04: "",
    mask05: "",
    n9501: "",
    n9502: "",
    n9503: "",
    n9504: "",
    ansi01: "",
    ansi02: "",
    ansi03: "",
    ansi04: "",
    en01: "",
    en02: "",
    en03: "",
    en04: "",
    en05: "",
    en06: "",
    en07: "",
    en08: "",
    en09: "",
    en10: "",
    expTime: "",
    sampleTime: "",
  })
  const [subServices, setSubServices] = useState([])
  const handleProduct = (e) => {
    const { name, value } = e.target
    setProduct({
      ...isProduct,
      [name]: value,
    })
  }

  const fetchData = useCallback(async () => {
    await apiService.getDocsInfo(id).then((res) => {
      let newData = res.data
      setProduct({
        id: newData._id,
        jobNumber: newData.jobNumber,
        organizeName: newData.organizeName,
        organizeBranch: newData.organizeBranch,
        status: newData.status,
        taxId: newData.taxId,
        address: newData.address,
        postcode: newData.postcode,
        contactName: newData.contactName,
        phoneNumber: newData.phoneNumber,
        faxNumber: newData.faxNumber,
        email: newData.email,
        service: newData.service,
        productSampleName: newData.productSampleName,
        productLotNumber: newData.productLotNumber,
        productSampleDescription: newData.productSampleDescription,
        reportType: newData.reportType,
        testType: newData.testType,
        receivingResult: newData.receivingResult,
        samplePicture: newData.samplePicture,
        uncertainty: newData.uncertainty,
        sampleReturn: newData.sampleReturn,
        payment: newData.payment,
        option: newData.option,
        mask01: newData.mask01,
        mask02: newData.mask02,
        mask03: newData.mask03,
        mask04: newData.mask04,
        mask05: newData.mask05,
        n9501: newData.n9501,
        n9502: newData.n9502,
        n9503: newData.n9503,
        n9504: newData.n9504,
        ansi01: newData.ansi01,
        ansi02: newData.ansi02,
        ansi03: newData.ansi03,
        ansi04: newData.ansi04,
        en01: newData.en01,
        en02: newData.en02,
        en03: newData.en03,
        en04: newData.en04,
        en05: newData.en05,
        en06: newData.en06,
        en07: newData.en07,
        en08: newData.en08,
        en09: newData.en09,
        en10: newData.en10,
        expTime: newData.expTime,
        sampleTime: newData.sampleTime,
      })
      setStatus(newData.status)
      setService(newData.service)
      setSubServices(newData.subServices)
      setSampleReturn(newData.sampleReturn)
      setSamplePicture(newData.samplePicture)
      setUncertainty(newData.uncertainty)
    })
  }, [id])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const updateForm = async (e) => {
    isProduct.status = isStatus
    isProduct.samplePicture = isSamplePicture
    isProduct.uncertainty = isUncertainty
    isProduct.sampleReturn = isSampleReturn
    isProduct.service = isService
    e.preventDefault()
    try {
      const response = await apiService.updateProduct(id, isProduct).then(
        async () => {
          await apiService.updateProductStatus(id, isStatus)
          alert("update success!")
          window.location.reload()
        },
        (error) => {
          console.log(error)
          alert(error)
        }
      )
      console.log(response)
    } catch (err) {
      console.log(err)
      alert(err)
    }
  }
  const updateSubServiceStatus = async (e) => {
    e.preventDefault()
    try {
      const response = await apiService.updateProductStatus(id, isStatus).then(
        async () => {
          await apiService.updateSubServices(id, subServices).then(() => {
            alert("update success!")
            window.location.reload()
          })
        },
        (error) => {
          console.log(error)
          alert(error)
        }
      )
      console.log(response)
    } catch (err) {
      console.log(err)
      alert(err)
    }
  }
  const updateFormStatus = async (e) => {
    e.preventDefault()
    try {
      const response = await apiService.updateProductStatus(id, isStatus).then(
        async () => {
          await apiService.updateSubServices(id, subServices).then(() => {
            alert("update success!")
            window.location.reload()
          })
        },
        (error) => {
          console.log(error)
          alert(error)
        }
      )
      console.log(response)
    } catch (err) {
      console.log(err)
      alert(err)
    }
  }
  const handleDownloadCSV = async () => {
    await apiService.getDownloadCSV(isProduct.id)
  }
  const handleDownloadPDF = async () => {
    await apiService.getDownloadPDF(isProduct.id)
  }
  return {
    isProduct,
    isStatus,
    isService,
    subServices,
    isUncertainty,
    isSamplePicture,
    isSampleReturn,
    handleDownloadCSV,
    setSubServices,
    setService,
    setSampleReturn,
    handleProduct,
    setSamplePicture,
    setUncertainty,
    handleChangeStatus,
    updateForm,
    handleDownloadPDF,
    updateFormStatus,
    updateSubServiceStatus,
  }
}

export default useForm
