import React from "react"
import Sidebar from "../component/Sidebar/Sideber"
import Index from "../component/Index/Index"

const IndexPage = () => {
  return (
    <>
      <Sidebar />
      <Index />
    </>
  )
}

export default IndexPage
