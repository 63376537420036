import React, { useState, useEffect, useCallback } from "react"
import apiService from "../../../api/axios"
import { useParams } from "react-router-dom"

const useForm = () => {
  const { id } = useParams()
  const [isStatus, setStatus] = React.useState("")
  const handleChangeStatus = (event) => {
    setStatus(event.target.value)
  }
  const [isCustomer, setCustomer] = useState({
    id: "",
    customerId: "",
    organizeName: "",
    organizeBranch: "",
    taxId: "",
    address: "",
    postcode: "",
    contactName: "",
    phoneNumber: "",
    faxNumber: "",
    email: "",
    certificate: "",
    vatRegistration: "",
    other: "",
  })
  const handleCustomer = (e) => {
    const { name, value } = e.target
    setCustomer({
      ...isCustomer,
      [name]: value,
    })
  }

  const fetchData = useCallback(async () => {
    await apiService.getCustomerInfo(id).then((res) => {
      let newData = res.data
      setCustomer({
        id: newData._id,
        customerId: newData.customerId,
        organizeName: newData.organizeName,
        organizeBranch: newData.organizeBranch,
        taxId: newData.taxId,
        address: newData.address,
        postcode: newData.postcode,
        contactName: newData.contactName,
        phoneNumber: newData.phoneNumber,
        faxNumber: newData.faxNumber,
        email: newData.email,
        certificate: newData.certificate,
        vatRegistration: newData.vatRegistration,
        other: newData.other,
      })
      setStatus(newData.status)
    })
  }, [id])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const updateCustomer = async (e) => {
    isCustomer.status = isStatus
    e.preventDefault()
    try {
      const response = await apiService.updateCustomer(id, isCustomer).then(
        () => {
          alert("update success!")
          window.location.reload()
        },
        (error) => {
          console.log(error)
          alert(error)
        }
      )
      console.log(response)
    } catch (err) {
      console.log(err)
      alert(err)
    }
  }

  return {
    isCustomer,
    isStatus,
    updateCustomer,
    handleCustomer,
    handleChangeStatus,
  }
}

export default useForm
