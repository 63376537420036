import React, { useState, useEffect, useCallback } from "react"
import "./Styles.css"
import {
  TableCard,
  TableText,
  StatusText1,
  StatusText2,
  StatusText3,
  SearchInput,
  LabelInput,
  RowContainerInput,
  BtnSort,
  TextHeadTable,
} from "./indexElements"
import apiService from "../../api/axios"
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  Paper,
  TableRow,
  TablePagination,
  TableFooter,
  Box,
} from "@mui/material"
import IconButton from "@mui/material/IconButton"
import FirstPageIcon from "@mui/icons-material/FirstPage"
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight"
import LastPageIcon from "@mui/icons-material/LastPage"
import { useTheme } from "@mui/material/styles"
import PropTypes from "prop-types"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import * as Mdui from "@mui/icons-material"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"

function TablePaginationActions(props) {
  const theme = useTheme()
  const { count, page, rowsPerPage, onPageChange } = props

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  )
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
}

const IndexTable = () => {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [data, setDatas] = useState([])
  const [search, setSearch] = useState("")
  const [filteredResults, setFilteredResults] = useState([])
  const [sortDate, setSortDate] = useState(true)

  const fetchData = useCallback(async () => {
    await apiService.getCustomerList().then((response) => {
      if (sortDate === false) {
        let newData = response.data.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        )
        setDatas(newData)
        setFilteredResults(newData)
      } else {
        let newData = response.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )
        setDatas(newData)
        setFilteredResults(newData)
      }
    })
  }, [sortDate])
  useEffect(() => {
    fetchData()
  }, [fetchData])

  const searchItems = (searchValue) => {
    setSearch(searchValue)
    if (search !== "") {
      const filteredData = data.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(search.toLowerCase())
      })
      setFilteredResults(filteredData)
    } else {
      setFilteredResults(data)
    }
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  const showDetails = (id) => {
    window.location = "/info/" + id
  }
  return (
    <>
      <TableCard>
        <RowContainerInput>
          <TableText>รายการคำขอ</TableText>
          <LabelInput>
            <Mdui.Search
              style={{
                marginTop: "0.7rem",
                marginLeft: "0.7rem",
                marginRight: "0.7rem",
                color: "#343891",
              }}
            />
            <SearchInput
              autoComplete="off"
              type="text"
              required
              placeholder="Search..."
              onChange={(e) => searchItems(e.target.value)}
            />
          </LabelInput>
        </RowContainerInput>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <TextHeadTable>CustomerID</TextHeadTable>
                </TableCell>
                <TableCell align="center">
                  <TextHeadTable>ชื่อบริษัท</TextHeadTable>
                </TableCell>
                <TableCell align="center">
                  <TextHeadTable>ชื่อผู้ติดต่อ</TextHeadTable>
                </TableCell>
                <TableCell align="center">
                  <TextHeadTable>เบอร์โทร</TextHeadTable>
                </TableCell>
                <TableCell align="center">
                  <BtnSort onClick={() => setSortDate(!sortDate)}>
                    วันที่ยื่น
                    {sortDate ? (
                      <ArrowDropDownIcon sx={{ fontSize: "20px" }} />
                    ) : (
                      <ArrowDropUpIcon sx={{ fontSize: "20px" }} />
                    )}
                  </BtnSort>
                </TableCell>
                <TableCell align="center">สถานะ</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? filteredResults.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredResults
              ).map((items) => (
                <TableRow key={items._id}>
                  <TableCell align="center">{items.customerId}</TableCell>
                  <TableCell align="center">{items.organizeName}</TableCell>
                  <TableCell align="center">{items.contactName}</TableCell>
                  <TableCell align="center">{items.phoneNumber}</TableCell>
                  <TableCell align="center">
                    {items.createdAt.split("T", 1)}
                  </TableCell>
                  <TableCell align="center">
                    {items.status === "verify" ? (
                      <StatusText1>{items.status}</StatusText1>
                    ) : items.status === "unverify" ? (
                      <StatusText2>{items.status}</StatusText2>
                    ) : (
                      <StatusText3>{items.status}</StatusText3>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={() => showDetails(items._id)}
                    >
                      <ChevronRightIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, { label: "All", value: -1 }]}
                  count={filteredResults.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </TableCard>
    </>
  )
}

export default IndexTable
