import styled from "styled-components"
import { Link as LinkR } from "react-router-dom"

export const HeroContainer = styled.div`
  background: #f3f4f6;
  display: flex;
  margin-left: 250px;
  min-height: 100vh;
  flex-direction: column;
`
export const WelcomeText = styled.h2`
  margin-top: 2rem;
  margin-left: 10%;
  font-weight: bold;
  color: #ffd100;
`
export const RowContainer = styled.div`
  display: flex;
  margin-top: 2rem;
  margin-left: 10%;
  margin-right: 5%;
  justify-content: space-between;
  @media only screen and (max-width: 1240px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
export const CardContent = styled.div`
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  background: #ffff;
  border-radius: 8px;
  padding: 1rem;
  color: #000;
  width: 250px;
  height: 160px;
  @media only screen and (max-width: 1240px) {
    margin-top: 1rem;
    width: 40vw;
    height: 20vh;
  }
`
export const Cardcolumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-left: 10%;
  margin-right: 10%;
`
export const Inputcolumn = styled.div`
  display: flex;
  flex-direction: column;
`
export const CardcolumnSecond = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.7rem;
`
export const HeadText = styled.div`
  background: #fff;
  border-radius: 30px;
  color: #545859;
  width: 80%;
  height: 23px;
  font-size: 14px;
  font-weight: 400;
  color: #363a3d;
  text-align: center;
`
export const TitleText = styled.div`
  margin-top: 1rem;
  color: #545859;
  width: 180px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
`
export const HeadCard = styled.div`
  width: 100%;
  height: 2.5rem;
  background: #ebecee;
  border-radius: 8px;
  color: #000;
  margin-bottom: 1rem;
  padding: 2rem;
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
`
export const TableCard = styled.div`
  margin-top: 1rem;
  width: 100%;
  min-height: 400px;
  background: #ebecee;
  border-radius: 8px;
  color: #000;
  margin-bottom: 1rem;
  padding: 2rem;
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
`
export const StatusText = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #3300ff;
`
export const StatusText1 = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #3300ff;
`
export const StatusText2 = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #cccc00;
`
export const StatusText3 = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #ff9900;
`
export const StatusText4 = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #cc00ff;
`
export const StatusText5 = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #33ccff;
`
export const StatusText6 = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #ff3399;
`
export const StatusText7 = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #00bc20;
`
export const StatusText8 = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #00bc20;
`
export const TableText = styled.h2`
  font-weight: 600;
  color: #545859;
`
export const LinkDetail = styled(LinkR)`
  font-weight: 300;
`
export const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const BtnRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 30%;
`
export const RadioRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const HeadRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
export const BeforeRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 4rem;
`
export const LabelModel = styled.label`
  color: #3c354e;
  font-size: 15px;
`
export const LabelHead = styled.label`
  color: #3c354e;
  font-size: 16px;
  font-weight: 600;
`
export const SelectStatus = styled.select`
  margin-left: 1rem;
  border-radius: 8px;
  width: 100%;
  height: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 1px solid #545859;
  outline: none;
  color: #3c354e;
  font-size: 15px;
`
export const SelectStatusSub = styled.select`
  width: 100%;
  border-radius: 8px;
  height: 2.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border: 2px solid #343891;
  outline: none;
  color: #343891;
  font-size: 15px;
  background: none;
`
export const RowContainerInput = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
export const RowSubservice = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 60%;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
export const LabelInput = styled.label`
  display: flex;
  background: #fff;
  border-radius: 6px;
  height: 3rem;
  width: 25%;
  border: 1px solid #d7d8d9;
`
export const SearchInput = styled.input`
  border: none;
  outline: none;
  color: #3c354e;
  font-size: 15px;
  border-radius: 6px;
  width: 24%;
  &:focus {
    border: none;
    display: inline-block;
  }
`
export const Input = styled.input`
  background: #fff;
  border-radius: 8px;
  width: 60%;
  height: 1rem;
  padding: 1rem;
  border: 1px solid #aaaaaa;
  outline: none;
  color: #3c354e;
  font-size: 15px;
  &:focus {
    display: inline-block;
  }
  &::placeholder {
    color: #b8babb;
    font-weight: 100;
    font-size: 15px;
  }
  &:disabled {
    background: #f1f1f1;
  }
`
export const CardContainer = styled.div`
  padding: 3rem;
`
export const BtnNext = styled.button`
  border-radius: 8px;
  text-align: center;
  background: #343891;
  white-space: nowrap;
  width: 130px;
  height: 40px;
  padding: 8px 0;
  color: #fff;
  font-size: 15px;
  outline: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  &:disabled {
    cursor: default;
    background: #c6c4d4;
    color: #ffff;
    border: none;
  }
`
export const BtnSort = styled.button`
  text-align: center;
  background: none;
  white-space: nowrap;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
`
export const TextHeadTable = styled.p`
  text-align: center;
  color: #000;
  font-size: 14px;
  font-weight: 500;
`
export const BtnPrev = styled.button`
  border-radius: 8px;
  text-align: center;
  background: #c6c4d4;
  white-space: nowrap;
  width: 130px;
  height: 40px;
  padding: 8px 0;
  color: #fff;
  font-size: 15px;
  outline: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  &:disabled {
    cursor: default;
    background: #c6c4d4;
    color: #ffff;
    border: none;
  }
`
export const RadioCheck = styled.input`
  margin-right: 5px;
  margin-top: 7px;
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 1px solid #545859;
`
