import React, { useState, useEffect } from "react"
import apiService from "../../api/axios"

// import { useNavigate } from "react-router-dom"
import {
  HeadLogo,
  HeroContainer,
  MainContainer,
  RowRemember,
  InputContainer,
  ButtonContainer,
  BtnLinkLogin,
  Remember,
  HeadText,
  LabelInput,
  LineContainer,
  Input,
  TextWarning,
} from "./loginElements"
import * as Mdui from "@mui/icons-material"
import KeyIcon from "@mui/icons-material/Key"
import { useNavigate } from "react-router-dom"

const Login = () => {
  const navigate = useNavigate()
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [isRemember, setRemember] = useState(false)

  //   const navigate = useNavigate()
  const handleLogin = async (e) => {
    e.preventDefault()
    try {
      const response = await apiService.Login(username, password).then(
        () => {
          if (isRemember === true) {
            localStorage.setItem("remember", isRemember)
            localStorage.setItem("username", username)
            localStorage.setItem("password", password)
          } else {
            localStorage.setItem("remember", isRemember)
            localStorage.setItem("username", "")
            localStorage.setItem("password", "")
          }
          navigate("/index")
          window.location.reload()
        },
        (error) => {
          alert(error.response.data.error)
          console.log(error)
        }
      )
      console.log(response)
    } catch (err) {
      alert(err)
      console.log(err)
    }
  }

  useEffect(() => {
    const remember = localStorage.getItem("remember")
    const name = localStorage.getItem("username")
    const pass = localStorage.getItem("password")
    if (remember === "true") {
      setUsername(name)
      setPassword(pass)
      setRemember(true)
    }
  }, [])
  return (
    <HeroContainer>
      <HeadLogo>Logo</HeadLogo>
      <HeadText>Admin Login</HeadText>
      <LineContainer>
        <hr />
      </LineContainer>
      <MainContainer>
        <InputContainer>
          <LabelInput>
            <Mdui.Person
              style={{
                marginTop: "0.7rem",
                marginLeft: "0.7rem",
                marginRight: "0.7rem",
                color: "#b1b1b1",
              }}
            />
            <Input
              autoComplete="off"
              type="text"
              onChange={(e) => setUsername(e.target.value)}
              value={username}
              required
              placeholder="Username"
            />
          </LabelInput>
          <LabelInput>
            <KeyIcon
              style={{
                marginTop: "0.7rem",
                marginLeft: "0.7rem",
                marginRight: "0.7rem",
                color: "#b1b1b1",
              }}
            />
            <Input
              autoComplete="off"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              required
              placeholder="Password"
            />
          </LabelInput>
          <RowRemember>
            <Remember
              type="checkbox"
              value="on"
              checked={isRemember}
              onChange={() => setRemember(!isRemember)}
            />
            <TextWarning> Remember me ?</TextWarning>
          </RowRemember>
        </InputContainer>
        <ButtonContainer>
          <BtnLinkLogin onClick={handleLogin}>Login</BtnLinkLogin>
        </ButtonContainer>
      </MainContainer>
    </HeroContainer>
  )
}

export default Login
