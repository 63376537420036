import axios from "axios"
import fileDownload from "js-file-download"
const BASE_URL = "https://api.vajiralab.co.th/v1"

const Login = (username, password) => {
  return axios
    .post(BASE_URL + "/admin/login", {
      username,
      password,
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("token", response.data.token)
      }

      return response.data
    })
}

const getDocsList = () => {
  return axios.get(BASE_URL + "/admin/form", {
    headers: {
      "Content-Type": "application/json",
    },
  })
}
const getDocsInfo = (id) => {
  return axios.get(BASE_URL + "/admin/form/" + id, {
    headers: {
      "Content-Type": "application/json",
    },
  })
}
const getCustomerList = () => {
  return axios.get(BASE_URL + "/customers", {
    headers: {
      "Content-Type": "application/json",
    },
  })
}
const getCustomerInfo = (id) => {
  return axios.get(BASE_URL + "/customers/" + id, {
    headers: {
      "Content-Type": "application/json",
    },
  })
}
const getCurrentUser = () => {
  return localStorage.getItem("token")
}
const logout = () => {
  localStorage.removeItem("token")
}

const updateCustomer = (id, isCustomer) => {
  const data = {
    organizeName: isCustomer.organizeName,
    organizeBranch: isCustomer.organizeBranch,
    taxId: isCustomer.taxId,
    address: isCustomer.address,
    postcode: isCustomer.postcode,
    contactName: isCustomer.contactName,
    phoneNumber: isCustomer.phoneNumber,
    faxNumber: isCustomer.faxNumber,
    email: isCustomer.email,
    certificate: isCustomer.certificate,
    vatRegistration: isCustomer.vatRegistration,
    other: isCustomer.other,
    status: isCustomer.status,
  }
  return axios.put(BASE_URL + "/customers/" + id, data, {
    headers: {
      "Content-Type": "application/json",
    },
  })
}
const updateProduct = (id, isProduct) => {
  const data = {
    organizeName: isProduct.organizeName,
    organizeBranch: isProduct.organizeBranch,
    taxId: isProduct.taxId,
    address: isProduct.address,
    postcode: isProduct.postcode,
    contactName: isProduct.contactName,
    phoneNumber: isProduct.phoneNumber,
    faxNumber: isProduct.faxNumber,
    email: isProduct.email,
    service: isProduct.service,
    productSampleName: isProduct.productSampleName,
    productLotNumber: isProduct.productLotNumber,
    productSampleDescription: isProduct.productSampleDescription,
    testType: isProduct.testType,
    receivingResult: isProduct.receivingResult,
    samplePicture: isProduct.samplePicture,
    uncertainty: isProduct.uncertainty,
    sampleReturn: isProduct.sampleReturn,
    payment: isProduct.payment,
    option: isProduct.option,
    mask01: isProduct.mask01,
    mask02: isProduct.mask02,
    mask03: isProduct.mask03,
    mask04: isProduct.mask04,
    mask05: isProduct.mask05,
    n9501: isProduct.n9501,
    n9502: isProduct.n9502,
    n9503: isProduct.n9503,
    n9504: isProduct.n9504,
    ansi01: isProduct.ansi01,
    ansi02: isProduct.ansi02,
    ansi03: isProduct.ansi03,
    ansi04: isProduct.ansi04,
    en01: isProduct.en01,
    en02: isProduct.en02,
    en03: isProduct.en03,
    en04: isProduct.en04,
    en05: isProduct.en05,
    en06: isProduct.en06,
    en07: isProduct.en07,
    en08: isProduct.en08,
    en09: isProduct.en09,
    en10: isProduct.en10,
  }
  return axios.put(BASE_URL + "/form/" + id, data, {
    headers: {
      "Content-Type": "application/json",
    },
  })
}
const updateSubServices = (id, subServices) => {
  const data = {
    subService: subServices,
  }
  return axios.put(BASE_URL + "/form/subService/status/" + id, data, {
    headers: {
      "Content-Type": "application/json",
    },
  })
}
const updateProductStatus = (id, isStatus) => {
  const data = {
    status: isStatus,
  }
  return axios.put(BASE_URL + "/form/status/" + id, data, {
    headers: {
      "Content-Type": "application/json",
    },
  })
}
const getDownloadCSV = (id) => {
  return axios
    .get(BASE_URL + "/generate/csv?id=" + id, {
      responseType: "blob",
      headers: {
        "Content-Type": "text/csv",
      },
    })
    .then((res) => {
      fileDownload(
        res.data,
        "ใบเสนอราคา.csv",
        "Content-Type=text/csv; charset=utf-8",
        "\uFEFF"
      )
    })
}
const getDownloadPDF = (id) => {
  return axios
    .get(BASE_URL + "/generate/pdf?formId=" + id, {
      responseType: "blob",
      headers: {
        "Content-Type": "application/pdf",
      },
    })
    .then((res) => {
      fileDownload(res.data, "แบบบันทึกคำขอ.pdf")
    })
}

const apiService = {
  Login,
  getDocsList,
  getDocsInfo,
  getCustomerList,
  getCustomerInfo,
  getCurrentUser,
  logout,
  updateProductStatus,
  updateCustomer,
  updateProduct,
  getDownloadPDF,
  getDownloadCSV,
  updateSubServices,
}

export default apiService
