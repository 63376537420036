import styled from "styled-components"

export const HeroContainer = styled.div`
  background: #fff;
  display: flex;
  height: 80vh;
  flex-direction: column;
  align-items: center;
`
export const HeadLogo = styled.h1`
  width: 80%;
  justify-content: flex-start;
  font-weight: bold;
`
export const MainContainer = styled.div`
  display: flex;
  margin-top: 2rem;
  padding: 2rem;
  flex-direction: column;
  background: #ebecee;
  border-radius: 8px;
  color: #000;
  @media only screen and (max-width: 320px) {
    width: 30vw;
    height: 60vh;
    hr {
      margin-bottom: 0.3rem;
    }
    h4 {
      font-size: small;
    }
  }
  @media only screen and (min-width: 360px) {
    width: 40vw;
    height: 40vh;
    h4 {
      font-size: small;
    }
  }
  @media only screen and (min-width: 411px) {
    width: 40vw;
    height: 40vh;
  }
  @media only screen and (min-width: 768px) {
    width: 40vw;
    height: 40vh;
  }
  @media only screen and (min-width: 1024px) {
    width: 40vw;
    height: 40vh;
  }
  @media only screen and (min-width: 1280px) {
    width: 30vw;
    height: 40vh;
  }
`
export const RowContainer = styled.div`
  display: flex;
  margin-left: 10%;
  margin-top: 1.5rem;
`
export const HeadText = styled.h1`
  font-weight: bold;
  font-size: 42px;
  color: #000;
`
export const LineContainer = styled.div`
  margin-top: 0.5rem;
  flex-direction: column;
  align-items: center;
  width: 15%;
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`
export const RowRemember = styled.div`
  margin-top: 1rem;
  display: flex;
  width: 100%;
`

export const ButtonContainer = styled.div`
  margin-top: 1.5rem;
  flex-direction: column;
  width: 100%;
  display: flex;
  align-items: center;
`

export const TextWarning = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #000;
  margin-left: 1rem;
`

export const Remember = styled.input`
  width: 19px;
  height: 19px;
  font-size: 14px;
  font-weight: 500;
  color: #d7d8d9;
`
export const BtnLinkLogin = styled.button`
  border-radius: 6px;
  text-align: center;
  background: #343891;
  width: 100%;
  height: 3rem;
  padding: 12px 0;
  color: #fff;
  font-size: 15px;
  outline: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
`
export const Input = styled.input`
  border: none;
  outline: none;
  color: #3c354e;
  font-size: 15px;
  border-radius: 6px;
  width: 99%;
  &:focus {
    border: none;
    display: inline-block;
  }
`
export const LabelInput = styled.label`
  margin-top: 1rem;
  display: flex;
  background: #fff;
  border-radius: 6px;
  height: 3rem;
  width: 100%;
  border: 1px solid #d7d8d9;
`
