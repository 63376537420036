import React from "react"
import Sidebar from "../component/Sidebar/Sideber"
import SendTestForm from "../component/Index/Details/SendTestForm"

const DetailsPage = () => {
  return (
    <div>
      <Sidebar />
      <SendTestForm />
    </div>
  )
}

export default DetailsPage
